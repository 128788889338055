"use client";
import React, { useState } from "react";
import { usePathname } from "next/navigation";
import Link from "next/link";
import { useTranslation } from "react-i18next";

export default function Nav({ isArrow = true, textColor = "", Linkfs = "" }) {
  const { t } = useTranslation();
  const pathname = usePathname();
  const [activeCategory, setActiveCategory] = useState(null);

  // Build the productsPages array using translation keys from your i18n JSON.
  const productsPages = [
    {
      heading: t("nav1.category1"), // "Workwear"
      links: [
        { href: "/workwear", text: t("nav1.subcategories.0.title1") }, // "Trousers"
        { href: "/workwear", text: t("nav1.subcategories.1.title2") }, // "Jackets"
        { href: "/workwear", text: t("nav1.subcategories.2.title3") }, // "Waistcoats"
        { href: "/workwear", text: t("nav1.subcategories.3.title4") }, // "Pullovers"
        { href: "/workwear", text: t("nav1.subcategories.4.title5") }, // "Shirts"
        { href: "/workwear", text: t("nav1.subcategories.6.title7") }, // "Aprons"
      ],
    },
    {
      heading: t("nav3.category3"), // "Hand/Arm Protection"
      links: [
        {
          href: "/hand-arm-protection",
          text: t("nav3.subcategories.0.title1"),
        }, // "Arm Protection"
        {
          href: "/hand-arm-protection",
          text: t("nav3.subcategories.1.title2"),
        }, // "Antistatic Gloves"
        {
          href: "/hand-arm-protection",
          text: t("nav3.subcategories.2.title3"),
        }, // "Chemical Protective Gloves"
        {
          href: "/hand-arm-protection",
          text: t("nav3.subcategories.3.title4"),
        }, // "Mechanic's Gloves"
        {
          href: "/hand-arm-protection",
          text: t("nav3.subcategories.6.title7"),
        }, // "Welding Gloves"
        {
          href: "/hand-arm-protection",
          text: t("nav3.subcategories.7.title8"),
        }, // "Puncture Protection Gloves"
        {
          href: "/hand-arm-protection",
          text: t("nav3.subcategories.8.title9"),
        }, // "Winter Gloves"
        {
          href: "/hand-arm-protection",
          text: t("nav3.subcategories.9.title10"),
        }, // "Leather Gloves"
        {
          href: "/hand-arm-protection",
          text: t("nav3.subcategories.10.title11"),
        }, // "Nitrile Gloves"
        {
          href: "/hand-arm-protection",
          text: t("nav3.subcategories.11.title12"),
        }, // "Knitted Gloves"
        {
          href: "/hand-arm-protection",
          text: t("nav3.subcategories.12.title13"),
        }, // "PVC Gloves"
        {
          href: "/hand-arm-protection",
          text: t("nav3.subcategories.13.title14"),
        }, // "Cotton Gloves"
        {
          href: "/hand-arm-protection",
          text: t("nav3.subcategories.14.title15"),
        }, // "Disposable Gloves"
      ],
    },
    {
      heading: t("nav4.category4"), // "Foot Protection"
      links: [
        { href: "/foot-protection", text: t("nav4.subcategories.0.title1") }, // "Clogs"
        { href: "/foot-protection", text: t("nav4.subcategories.1.title2") }, // "Low Shoes"
        { href: "/foot-protection", text: t("nav4.subcategories.2.title3") }, // "Sandals"
        { href: "/foot-protection", text: t("nav4.subcategories.3.title4") }, // "Slippers"
        { href: "/foot-protection", text: t("nav4.subcategories.4.title5") }, // "Boots"
        { href: "/foot-protection", text: t("nav4.subcategories.5.title6") }, // "Winter Boots"
      ],
    },
    {
      heading: t("nav5.category6"), // "Eye Protection"
      links: [
        { href: "/eye-protection", text: t("nav5.subcategories.2.title3") }, // "Ultraviolet Filter"
        { href: "/eye-protection", text: t("nav5.subcategories.3.title4") }, // "Sunglare Filter"
      ],
    },
    {
      heading: t("nav6.category7"), // "Head Protection"
      links: [
        { href: "/head-protection", text: t("nav6.subcategories.0.title1") }, // "Safety Helmets"
      ],
    },
    {
      heading: t("nav7.category8"), // "Kids Selection"
      links: [
        { href: "/kids-selection", text: t("nav7.subcategories.0.title1") }, // "Garments"
        { href: "/kids-selection", text: t("nav7.subcategories.1.title2") }, // "Hand Protection"
      ],
    },
  ];

  // Helper to check if a specific link is active based on the current pathname
  const isMenuActive = (menuItem) => {
    const pathSegments = pathname.split("/");
    return menuItem.href?.split("/")[1] === pathSegments[1];
  };

  // Determine if any of the product links is active for the top-level "Product" link
  const isProductActive = () => {
    return productsPages.some((page) =>
      page.links.some((link) => pathname.startsWith(link.href))
    );
  };

  return (
    <li className="menu-item">
      <a
        href="#"
        className={`item-link ${Linkfs} ${textColor} ${
          isProductActive() ? "activeMenu" : ""
        }`}
      >
        {t("nav_menu.nav1")}
        {isArrow && <i className="icon icon-arrow-down" />}
      </a>
      <div className="sub-menu mega-menu">
        <div className="container">
          <div className="row">
            {productsPages.map((menu, index) => (
              <div className="col-lg-2" key={index}>
                <div className="mega-menu-item">
                  <div
                    className="menu-heading"
                    onClick={() => setActiveCategory(menu.heading)}
                  >
                    {menu.heading}
                  </div>
                  <ul className="menu-list">
                    {activeCategory === menu.heading &&
                      menu.links.map((link, linkIndex) => (
                        <li key={linkIndex}>
                          <Link
                            href={link.href}
                            className={`menu-link-text link ${
                              isMenuActive(link) ? "activeMenu" : ""
                            }`}
                          >
                            {link.text}
                          </Link>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </li>
  );
}
