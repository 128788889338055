"use client";
import React, { useEffect, useRef, useState } from "react";
import Image from "next/image";
import Link from "next/link";
import LanguageSelect from "../common/LanguageSelect";
import CurrencySelect from "../common/CurrencySelect";
import emailjs from "@emailjs/browser";
import { useTranslation } from "react-i18next";

// Define your footer links here with translation keys
const footerLinks = [
  { href: "/workwear", text: "nav1.category1" },
  { href: "/protective-clothing", text: "nav2.category2" },
  { href: "/hand-arm-protection", text: "nav3.category3" },
  { href: "/foot-protection", text: "nav4.category4" },
  { href: "/eye-protection", text: "nav5.category6" },
  { href: "/head-protection", text: "nav6.category7" },
  { href: "/kids-selection", text: "nav7.category8" },
];

export default function Footer1({ bgColor = "" }) {
  useEffect(() => {
    const headings = document.querySelectorAll(".footer-heading-moblie");

    const toggleOpen = (event) => {
      const parent = event.target.closest(".footer-col-block");
      parent.classList.toggle("open");
    };

    headings.forEach((heading) => {
      heading.addEventListener("click", toggleOpen);
    });

    return () => {
      headings.forEach((heading) => {
        heading.removeEventListener("click", toggleOpen);
      });
    };
  }, []);

  const formRef = useRef();
  const [success, setSuccess] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const { t } = useTranslation();

  const handleShowMessage = () => {
    setShowMessage(true);
    setTimeout(() => {
      setShowMessage(false);
    }, 2000);
  };

  const sendMail = (e) => {
    emailjs
      .sendForm("service_noj8796", "template_fs3xchn", formRef.current, {
        publicKey: "iG4SCmR-YtJagQ4gV",
      })
      .then((res) => {
        if (res.status === 200) {
          setSuccess(true);
          handleShowMessage();
          formRef.current.reset();
        } else {
          setSuccess(false);
          handleShowMessage();
        }
      });
  };

  return (
    <footer id="footer" className={`footer md-pb-70 ${bgColor}`}>
      <div className="footer-wrap">
        <div className="footer-body">
          <div className="container">
            <div className="row">
              {/* Footer Information */}
              <div className="col-xl-3 col-md-6 col-12">
                <div className="footer-infor">
                  <div className="footer-logo">
                    <Link href={`/`}>
                      <Image
                        alt="image"
                        src="/images/logo/logo.svg"
                        width="136"
                        height="21"
                      />
                    </Link>
                  </div>
                  <ul>
                    <li>
                      <a
                        href="https://maps.app.goo.gl/rgUitJe2oREGspqx5"
                        target="_blank"
                        title="Get direction"
                        rel="noreferrer"
                      >
                        Address: Josef-Schregel-Straße 68, <br /> 52349 Düren,
                        Germany
                      </a>
                    </li>
                    <li>
                      <p>
                        Email:{" "}
                        <a href="mailto:info@kompakt-arbeitsschutz.de">
                          info@kompakt-arbeitsschutz.de
                        </a>
                      </p>
                    </li>
                    <li>
                      <p>
                        Phone:{" "}
                        <a href="tel:+49 2421 9590177">+49 2421 9590177</a>
                      </p>
                    </li>
                  </ul>
                  <Link href={`/contact-1`} className="tf-btn btn-line">
                    {t("footer_text.title1")}
                    <i className="icon icon-arrow1-top-left" />
                  </Link>
                  <ul className="tf-top-bar_item tf-social-icon d-flex gap-10">
                    <li>
                      <a
                        href="https://www.facebook.com/KompaktArbeitsschutz"
                        target="_blank"
                        title="facebook kompakt arbeitsschutz"
                        aria-label="facebook kompakt arbeitsschutz"
                        className="box-icon w_28 round social-facebook bg_line"
                      >
                        <i className="icon fs-12 icon-fb" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        className="box-icon w_28 round social-twiter bg_line"
                        aria-label="twitter kompakt arbeitsschutz"
                        title="twitter kompakt arbeitsschutz"
                      >
                        <i className="icon fs-10 icon-Icon-x" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/kompakt.arbeitsschutz?igsh=bTQ2OXB0ZmtsaWV3"
                        target="_blank"
                        aria-label="instagram kompakt arbeitsschutz"
                        title="instagram kompakt arbeitsschutz"
                        className="box-icon w_28 round social-instagram bg_line"
                      >
                        <i className="icon fs-12 icon-instagram" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* Footer Links */}
              <div className="col-xl-3 col-md-6 col-12 footer-col-block">
                <div className="footer-heading footer-heading-desktop">
                  <h6>{t("footer_text.title2")}</h6>
                </div>
                <div className="footer-heading footer-heading-moblie">
                  <h6>{t("footer_text.title2")}</h6>
                </div>
                <ul className="footer-menu-list tf-collapse-content">
                  {footerLinks.map((link, index) => (
                    <li key={index}>
                      <Link href={link.href} className="footer-menu_item">
                        {t(link.text)}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              {/* About Links (if you wish to integrate these similarly, you can define them inline too) */}
              <div className="col-xl-3 col-md-6 col-12 footer-col-block">
                <div className="footer-heading footer-heading-desktop">
                  <h6>{t("footer_text.title3")}</h6>
                </div>
                <div className="footer-heading footer-heading-moblie">
                  <h6>{t("footer_text.title3")}</h6>
                </div>
                <ul className="footer-menu-list tf-collapse-content">
                  {/* Replace the below with your about links integration if needed */}
                  {/* For now, these links remain as static text */}
                  <li>
                    <Link href="/about-us" className="footer-menu_item">
                      {t("nav_menu.nav3")}
                    </Link>
                  </li>
                  {/* <li>
                    <Link href="/careers" className="footer-menu_item">
                      Careers
                    </Link>
                  </li> */}
                  {/* <li>
                    <Link href="/blog" className="footer-menu_item">
                      Blog
                    </Link>
                  </li> */}
                  <li>
                    <Link href="/contact-1" className="footer-menu_item">
                      {t("nav_menu.nav5")}
                    </Link>
                  </li>
                </ul>
              </div>
              {/* Newsletter Section */}
              <div className="col-xl-3 col-md-6 col-12">
                <div className="footer-newsletter footer-col-block">
                  <div className="footer-heading footer-heading-desktop">
                    <h6>{t("footer_text.title4")}</h6>
                    <p>{t("footer_text.title5")}</p>
                  </div>
                  <div className="footer-heading footer-heading-moblie">
                    <p>{t("footer_text.title4")}</p>
                  </div>
                  {/* Newsletter subscription code can be placed here */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Footer Bottom */}
        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="footer-bottom-wrap d-flex gap-20 flex-wrap justify-content-between align-items-center">
                  <div className="footer-menu_item">
                    © {new Date().getFullYear()} {t("footer_text.title6")}
                  </div>
                  <div className="tf-payment">
                    <a href="/Impressum">Impressum</a>
                    <a href="/Datenschutz">Datenschutz</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
